const createUrlObj = (url: string): URL => new URL(url, 'http://localhost');

export const getQueryParam = (url: string, name: string): string | undefined => {
  const urlObj = createUrlObj(url);
  const param = urlObj.searchParams.get(name);

  return param ?? undefined;
}

export const getAllQueryParams = (url: string, name: string): string[] | undefined => {
  const urlObj = createUrlObj(url);
  const searchParams = urlObj.searchParams;

  return searchParams.has(name) ? searchParams.getAll(name) : undefined;
}

export const getQuery = (url: string): string => {
  const urlObj = createUrlObj(url);
  return urlObj.search;
}
