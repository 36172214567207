import { useMemo, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import type { Swiper as SwiperClass } from 'swiper/types';

import { Container } from '@/components/container';
import { ExternalLinkButton, LinkButton } from '@/components/ui/buttons/buttons/Button';
import { SliderButtonLeft } from '@/components/ui/buttons/slider-buttons/SliderButtonLeft';
import { SliderButtonRight } from '@/components/ui/buttons/slider-buttons/SliderButtonRight';
import { Skeleton } from '@/components/ui/loaders/Skeletons/Skeletons';

import { useMediaQuery } from '@/hooks/useMediaQuery';
import { useVisibleItems } from '@/hooks/useVisibleItems';

import { useGetYandexReviewsQuery } from '@/store/api/reviews.api';

import { ISwiper } from '@/types/ISwiper';
import { IYandexReviewNew } from '@/types/new/reviews';

import styles from './YandexReviews.module.scss';
import { YandexReviewItem } from './yandex-review-item/YandexReviewItem';

type Props = {
  variant?: 'reviewPage' | 'mainPage';
  limit?: number;
  slidesPerView?: number;
};

const YandexReviews = ({ variant = 'reviewPage', limit, slidesPerView = 4 }: Props) => {
  const { data, isLoading } = useGetYandexReviewsQuery({ limit });

  const [swiper, setSwiper] = useState<ISwiper | null>(null);
  const isMobile = useMediaQuery('768px');
  const { visibleItems, containerRef } = useVisibleItems(!isLoading ? data : [], isMobile ? 1 : 3);
  const breakPoints = useMemo(
    () => ({
      0: {
        slidesPerView: 1.1,
      },
      480: {
        slidesPerView: 1.25,
      },
      768: {
        slidesPerView: 2,
      },
      998: {
        slidesPerView: 3,
      },
      1200: {
        slidesPerView: slidesPerView,
      },
    }),
    [slidesPerView]
  );

  const handleTapNext = () => swiper?.slideNext();
  const handleTapPrev = () => swiper?.slidePrev();
  return (
    <Container>
      <div className={styles.top_place}>
        <div>
          <span>
            <svg width="35" height="34">
              <use href={`/static/icons/sprite-yandex.svg#yandex-logo`} />
            </svg>
          </span>
          <span>
            <svg width={121} height={24}>
              <use href={`/static/icons/sprite-yandex.svg#yandex-logo-text`} />
            </svg>
          </span>
        </div>
        <div>
          <div className={styles.header__arrows}>
            <SliderButtonLeft onClick={handleTapPrev} />
            <SliderButtonRight onClick={handleTapNext} />
          </div>
        </div>
      </div>

      <section className={styles.reviews}>
        <Swiper
          ref={containerRef}
          spaceBetween={16}
          breakpoints={breakPoints}
          className={styles.swiper}
          onSwiper={(swiper: SwiperClass) => setSwiper(swiper)}
        >
          {isLoading
            ? [...new Array(4)].map((_, i) => (
                <SwiperSlide key={i}>
                  <Skeleton className={styles.skeleton} />
                </SwiperSlide>
              ))
            : visibleItems?.map((review: IYandexReviewNew) => (
                <SwiperSlide key={review.id}>
                  <YandexReviewItem key={review.id} review={review} />
                </SwiperSlide>
              ))}
        </Swiper>

        <div className={styles.wrapper_button}>
          {variant === 'reviewPage' ? (
            <ExternalLinkButton
              href="https://yandex.ru/maps/org/azalia_now/149447335828/reviews/?ll=37.523860%2C55.764673&z=17"
              className={styles.button}
            >
              Посмотреть на Яндекс.Картах
            </ExternalLinkButton>
          ) : (
            <LinkButton href="/reviews" styleVariant="outlined" className={styles.button_link}>
              Посмотреть все отзывы
            </LinkButton>
          )}
        </div>
      </section>
    </Container>
  );
};
export default YandexReviews;
