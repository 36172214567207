import clsx from 'clsx';
import { FC, HTMLAttributes } from 'react';

import styles from './heading.module.scss';

type HeadingProps = {
  variant: 'small' | 'average' | 'big';
  text: string;
};

export const H1: FC<HTMLAttributes<HTMLDivElement> & HeadingProps> = ({
  text,
  className,
  variant = 'big',
}) => {
  return <h1 className={clsx(className, styles[variant])}>{text}</h1>;
};

export const H2: FC<HTMLAttributes<HTMLDivElement> & HeadingProps> = ({
  text,
  className,
  variant = 'average',
}) => {
  return <h2 className={clsx(className, styles[variant])}>{text}</h2>;
};

export const H3: FC<HTMLAttributes<HTMLDivElement> & HeadingProps> = ({
  text,
  className,
  variant = 'average',
}) => {
  return <h3 className={clsx(className, styles[variant])}>{text}</h3>;
};

export const H4: FC<HTMLAttributes<HTMLDivElement> & HeadingProps> = ({
  text,
  className,
  variant = 'small',
}) => {
  return <h4 className={clsx(className, styles[variant])}>{text}</h4>;
};

export const H5: FC<HTMLAttributes<HTMLDivElement> & HeadingProps> = ({
  text,
  className,
  variant = 'small',
}) => {
  return <h5 className={clsx(className, styles[variant])}>{text}</h5>;
};

export const H6: FC<HTMLAttributes<HTMLDivElement> & HeadingProps> = ({
  text,
  className,
  variant = 'small',
}) => {
  return <h6 className={clsx(className, styles[variant])}>{text}</h6>;
};
