import { ReactNode } from 'react';
import { createPortal } from 'react-dom';

import { useHasMounted } from '@/hooks/useHasMounted';

type PortalProps = {
  children?: ReactNode;
};

const Portal = ({ children }: PortalProps) => {
  const hasMounted = useHasMounted();

  if (!hasMounted) {
    return null;
  }

  return createPortal(children, document.querySelector<HTMLDivElement>('#myportal')!);
};

export default Portal;
