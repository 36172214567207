import { IProductMedia } from '@/types/new/products';

/**
 * Возвращает URL изображения из поля formats в Media объекте. Если формата, который указали вторым параметром нет, то пытается взять формат выше
 *
 * @param {IProductMedia} mediaData - Media объект с вложенным внутрь formats
 * @param {'thumbnail' | 'small' | 'medium' | 'large'} format - Предпочитаемый формат
 * @return {string} Возвращаемый URL
 */
export const getImageFormatUrl = (
  mediaData: IProductMedia,
  format: 'thumbnail' | 'small' | 'medium' | 'large'
): string => {
  const fallbackUrl = mediaData.url;

  switch (format) {
    case 'thumbnail':
      return (
        mediaData?.formats?.thumbnail?.url ||
        mediaData?.formats?.small?.url ||
        mediaData?.formats?.medium?.url ||
        mediaData?.formats?.large?.url ||
        fallbackUrl
      );
    case 'small':
      return (
        mediaData?.formats?.small?.url ||
        mediaData?.formats?.medium?.url ||
        mediaData?.formats?.large?.url ||
        fallbackUrl
      );
    case 'medium':
      return mediaData?.formats?.medium?.url || mediaData?.formats?.large?.url || fallbackUrl;
    case 'large':
      return mediaData?.formats?.large?.url || fallbackUrl;
    default:
      return fallbackUrl;
  }
};
