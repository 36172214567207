export const overlayVariants = {
  hidden: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

export const contentVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};

export const filterModalDesktopVariant = {
  initial: {
    x: '100%',
    transition: {
      type: 'spring',
      damping: 40,
      stiffness: 400,
    },
  },
  animate: {
    x: 0,
    transition: {
      type: 'spring',
      damping: 40,
      stiffness: 400,
    },
  },
  exit: {
    x: '100%',
    transition: {
      type: 'spring',
      damping: 40,
      stiffness: 400,
    },
  },
};

export const filterModalMobileVariant = {
  initial: {
    y: '100%',
    transition: {
      type: 'spring',
      damping: 40,
      stiffness: 400,
    },
  },
  animate: {
    y: 0,
    transition: {
      type: 'spring',
      damping: 40,
      stiffness: 400,
    },
  },
  exit: {
    y: '100%',
    transition: {
      type: 'spring',
      damping: 40,
      stiffness: 400,
    },
  },
};
