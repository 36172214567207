export const jivoActions = {
  open() {
    try {
      // @ts-ignore
      // eslint-disable-next-line no-undef
      jivo_api.open();
    } catch {
      console.log('jivo api not loaded');
    }
  },
  close() {
    try {
      // @ts-ignore
      // eslint-disable-next-line no-undef
      jivo_api.close();
    } catch {
      console.log('jivo api not loaded');
    }
  },
};
