import clsx from 'clsx';

import { Image } from '@/components/image';

import { getImageFormatUrl } from '@/helpers/string/getImageFormatUrl';

import { IYandexReviewNew } from '@/types/new/reviews';

import styles from './YandexReviewItem.module.scss';

const starIcon = (
  <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.5 0L8.02824 4.05836L12.6819 4.1459L8.97275 6.74164L10.3206 10.8541L6.5 8.4L2.6794 10.8541L4.02725 6.74164L0.318133 4.1459L4.97176 4.05836L6.5 0Z"
      fill="#e6e6e6"
    />
  </svg>
);

export const YandexReviewItem = ({ review }: { review: IYandexReviewNew }) => {
  return (
    <div className={styles.review}>
      <div className={styles.review__person_information}>
        {!!review?.avatar.length && (
          <div className={styles.review__person_information_ava}>
            <Image src={getImageFormatUrl(review.avatar[0], 'thumbnail')} fill alt={review.name} />
          </div>
        )}
        <div className={styles.review__person_information_data}>
          <div className={styles.review__person_information_data_name}>{review.name}</div>
          <div className={styles.review__person_information_data_role}>{review.role}</div>
        </div>
      </div>

      <div className={styles.review__rating}>
        {[...new Array(5)].map((_, i) => (
          <div
            className={
              i + 1 <= review.stars_rating
                ? clsx(styles.review__rating_star, styles.review__rating_star_active)
                : styles.review__rating_star
            }
            key={i}
          >
            {starIcon}
          </div>
        ))}
      </div>

      <div className={styles.review__content}>{review.text}</div>
    </div>
  );
};
