import clsx from 'clsx';
import { AnimatePresence, MotionProps, PanInfo, motion, useDragControls } from 'framer-motion';
import { HTMLAttributes, PropsWithChildren } from 'react';

import {
  modalDesktopVariants,
  modalMobileVariantsVertical,
} from '@/components/ui/modals/modal-wrapper/modal-wrapper.animations';
import { overlayVariants } from '@/components/ui/modals/motion-variants';

import { useMediaQuery } from '@/hooks/useMediaQuery';

import Portal from '../portal/Portal';

import styles from './ModalWrapper.module.scss';

interface ModalWrapperProps extends PropsWithChildren {
  onClose: () => void;
  isOpen: boolean;
  pt?: {
    overlay?: HTMLAttributes<HTMLDivElement> & MotionProps;
    modal?: HTMLAttributes<HTMLDivElement> & MotionProps;
    outerWrapper?: HTMLAttributes<HTMLDivElement> & MotionProps;
    innerWrapper?: HTMLAttributes<HTMLDivElement> & MotionProps;
    bottomSheet?: HTMLAttributes<HTMLDivElement> & MotionProps;
  };
}

const ModalWrapper = ({ children, onClose, isOpen = false, pt }: ModalWrapperProps) => {
  const isDesktop = useMediaQuery('769px', 'min-width');
  const controls = useDragControls();

  const closeHandler = () => {
    document.body.classList.remove('no-scroll');
    onClose();
  };

  function startDrag(event: any) {
    controls.start(event);
  }

  const mobileCloseOptions: MotionProps = {
    drag: 'y',
    dragConstraints: {
      top: 0,
      bottom: 0,
    },
    dragElastic: {
      top: 0,
      bottom: 0.25,
    },
    dragListener: false,
    dragControls: controls,
    onDragEnd(event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) {
      if (info.offset.y > 150 || info.velocity.y > 40) onClose();
    },
  };

  return (
    <Portal>
      <AnimatePresence initial={true}>
        {isOpen && (
          <div className={clsx(styles.wrapper)} key="modal">
            <motion.div
              {...pt?.overlay}
              className={clsx(styles.overlay, pt?.overlay?.className)}
              variants={overlayVariants}
              initial="hidden"
              animate="animate"
              exit="exit"
              onClick={closeHandler}
            />
            <div
              {...pt?.outerWrapper}
              className={clsx(styles.modalOuterWrapper, pt?.outerWrapper?.className)}
            >
              <motion.div
                {...pt?.innerWrapper}
                {...(isDesktop ? {} : mobileCloseOptions)}
                className={clsx(styles.modalInnerWrapper, pt?.innerWrapper?.className)}
                variants={isDesktop ? modalDesktopVariants : modalMobileVariantsVertical}
                initial="initial"
                animate="animate"
                exit="exit"
              >
                <div
                  className={styles.draggable}
                  style={{ touchAction: 'none' }}
                  onPointerDown={startDrag}
                />
                <div
                  {...pt?.modal}
                  className={clsx(
                    isDesktop
                      ? [styles.modal, pt?.modal?.className]
                      : [styles.bottomSheet, pt?.bottomSheet?.className]
                  )}
                >
                  {children}
                </div>
              </motion.div>
            </div>
          </div>
        )}
      </AnimatePresence>
    </Portal>
  );
};

export default ModalWrapper;
