import clsx from 'clsx';
import { FC, HTMLAttributes, PropsWithChildren } from 'react';

import styles from './text-block.module.scss';

type Tag = 'p' | 'span' | 'div';

type TextBlockProps = {
  tag?: Tag;
};

const TextBlock: FC<HTMLAttributes<HTMLDivElement> & PropsWithChildren<TextBlockProps>> = ({
  className,
  tag = 'p',
  children,
}) => {
  if (tag === 'p') return <p className={clsx(className, styles.text)}>{children}</p>;
  if (tag === 'div') return <div className={clsx(className, styles.text)}>{children}</div>;
  if (tag === 'span') return <span className={clsx(className, styles.text)}>{children}</span>;

  return <p className={clsx(className, styles.text)}>{children}</p>;
};

export default TextBlock;
