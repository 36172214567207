import { isAxiosError } from 'axios';

export default function getErrorString(err: unknown): string {
  if (isAxiosError(err)) {
    const universalErr = err.response?.data?.error;
    const errKey: null | string = universalErr?.code;

    return (errKey && ERRORS[errKey]) || universalErr?.message || err.message;
  } else if (err instanceof Error) {
    return err.message;
  } else {
    return 'Неизвестная ошибка';
  }
}

const ERRORS: Record<string, string> = {
  order_037: 'Доставка на выбранную дату недоступна',
  order_049: 'Доставка на выбранный адрес недоступна',
};
