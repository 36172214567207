import { DeliveryStatusNew, OrderStatusNew } from '@/types/new/customers';

export const getClientOrderStatus = (
  floristStatus: OrderStatusNew,
  deliveryStatus: DeliveryStatusNew
): string => {
  if (floristStatus === 'cancelled') {
    return 'Отменен';
  }

  const shouldShowFloristStatus =
    ![
      'assembling_finished',
      'delivery_started',
      'delivery_in_progress',
      'arrived_to_delivery_address',
      'delivery_finished',
      'order_finished',
    ].includes(floristStatus) ||
    !deliveryStatus ||
    deliveryStatus === 'created';

  const orderClientStatusMap: Record<OrderStatusNew, string> = {
    created: 'Заказ не оплачен',
    payment_success: 'Заказ оплачен',
    payment_processing: 'Платеж в обработке',
    payment_error: 'Ошибка оплаты',
    accepted: 'Заказ принят флористом',
    assembling_started: 'Флорист собирает ваш заказ',
    assembling_finished: 'Заказ готов',
    delivery_started: 'Заказ готов',
    delivery_in_progress: 'Заказ готов',
    arrived_to_delivery_address: 'Заказ готов',
    delivery_finished: 'Заказ готов',
    order_finished: 'Заказ готов',
    cancelled: 'Отменен',
  };

  const deliveryClientStatusMap: Record<NonNullable<DeliveryStatusNew>, string> = {
    created: '',
    courier_search: 'Назначается курьер',
    courier_found: 'Курьер назначен',
    in_delivery: 'На доставке',
    now_delivering: 'На доставке',
    at_store: 'Курьер назначен',
    finished: 'Заказ завершен',
  };

  return shouldShowFloristStatus
    ? orderClientStatusMap[floristStatus]
    : deliveryClientStatusMap[deliveryStatus];
};
