export const wordTimeChoice = (minute: number) => {
  const minuteForms = ['минута', 'минуты', 'минут'];
  const hourForms = ['час', 'часа', 'часов'];

  minute = Math.abs(minute) % 100;
  const m = minute % 10;
  if (minute > 10 && minute < 20) return { minute: minuteForms[2], hour: hourForms[2] };
  if (m > 1 && m < 5) return { minute: minuteForms[1], hour: hourForms[1] };
  if (m == 1) return { minute: minuteForms[0], hour: hourForms[0] };
  return { minute: minuteForms[2], hour: hourForms[2] };
};

export const countBonus = (cashback: number, price: number) => {
  const balls = +((price / 100) * (cashback || 5)).toFixed(0);
  return balls;
};
