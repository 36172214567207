import clsx from 'clsx';
import { FC, HTMLAttributes, PropsWithChildren } from 'react';

import styles from './block-content.module.scss';

type BlockContentProps = {
  tag?: 'div' | 'section';
  variant?: 'gap15' | 'gap10' | 'gap20';
};

const BlockContent: FC<PropsWithChildren<BlockContentProps> & HTMLAttributes<HTMLDivElement>> = ({
  tag = 'div',
  children,
  className,
  variant = 'gap15',
}) => {
  if (tag === 'div') {
    return <div className={clsx(className, styles.content, styles[variant])}>{children}</div>;
  }

  if (tag === 'section') {
    // @ts-ignore
    return (
      <section className={clsx(className, styles.content, styles[variant])}>{children}</section>
    );
  }

  return <div className={clsx(className, styles.content, styles[variant])}>{children}</div>;
};

export default BlockContent;
