export const SOCIAL_LINKS = {
  main_phone_number: '+74958220709',
  additional_phone_number: '+79119454819',
  instagram: 'https://www.instagram.com/azalianow.ru',
  telegram: 'https://t.me/azalianow',
  telegram_support: 'https://t.me/azalianow_support_bot',
  whatsapp_support: 'https://wa.me/+79119454819',
  whatsapp_support_additional: 'https://wa.me/+74958220709',
  vk: 'https://vk.com/azalianow',
  email: 'support@azalianow.ru',
  dzen: 'https://dzen.ru/azalianow',
  youtube: 'https://www.youtube.com/@AzaliaNowru',
  odnoklassniki: 'https://ok.ru/group/70000005083398',
};
