import { IProductAttribute, IProductCategory } from '@/types/new/products';

export const parseUrlToFilters = (
  url: string,
  categories: IProductCategory[],
  availableAttributes: IProductAttribute[]
) => {
  const urlObj = new URL(url, 'http://localhost');

  const slugs = urlObj.pathname.split('/');
  const category = categories
    .filter((cat) => slugs.some((slug) => slug === cat.slug))
    .map((cat) => cat.id);

  const searchParamsKeys = [...new Set(urlObj.searchParams.keys())];

  const filtersIds = Object.fromEntries(
    availableAttributes
      .map((attribute) => {
        if (['flower', 'color'].includes(attribute.slug)) {
          return [
            attribute.slug,
            attribute.attr_values
              .filter((attrValue) => slugs.includes(attrValue.slug))
              .map((attrValue) => attrValue.id),
          ];
        }

        if (searchParamsKeys.includes(attribute.slug)) {
          const urlAttrParams = urlObj.searchParams.getAll(attribute.slug);
          return [
            attribute.slug,
            attribute.attr_values
              .filter(
                (attrValue) =>
                  urlAttrParams.includes(attrValue.slug) ||
                  urlAttrParams.includes(attrValue.id.toString())
              )
              .map((attrValue) => attrValue.id),
          ];
        }

        return [];
      })
      .filter(([, value]) => value?.length)
  );

  const restParams = Object.fromEntries(
    searchParamsKeys
      .filter((spk) => !Object.keys(filtersIds).includes(spk))
      .map((key) => [
        key,
        urlObj.searchParams
          .getAll(key)
          .map((v) => (/^\d+$/.test(v) ? Number(v) : decodeURIComponent(v))),
      ])
  );

  return {
    ...(category.length ? { category } : {}),
    ...filtersIds,
    ...restParams,
  };
};
