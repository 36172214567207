import clsx from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { ReactNode } from 'react';

import stl from './MenuLink.module.scss';

interface IMenuLinkProps {
  href?: string;
  text: string;
  testId?: string;
  icon: ReactNode;
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
  notEmpty?: boolean;
}

const MenuLink = ({
  href,
  text,
  testId,
  icon,
  onClick,
  className,
  disabled,
  notEmpty,
}: IMenuLinkProps) => {
  const router = useRouter();
  const Component = disabled ? 'div' : Link;

  return (
    <Component
      {...((href ? { href } : {}) as any)}
      className={clsx(
        stl.menu_link,
        router.asPath === href && 'active',
        notEmpty && stl.not_empty,
        className
      )}
      data-testid={testId}
      onClick={onClick}
    >
      {icon}
      <p>{text}</p>
    </Component>
  );
};

export default MenuLink;
