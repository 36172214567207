import clsx from 'clsx';
import { FC, HTMLAttributes, memo } from 'react';

import styles from './skeletons.module.scss';

export const Skeleton: FC<HTMLAttributes<HTMLDivElement>> = memo(({ className }) => (
  <span className={clsx(className, styles.total_skeletons)} />
));

Skeleton.displayName = 'Skeleton';
