import { Variants } from 'framer-motion';

export const modalDesktopVariants: Variants = {
  initial: {
    opacity: 0,
    transition: {
      duration: 0.2,
      ease: 'easeIn',
    },
  },
  animate: {
    opacity: 1,
    transition: {
      duration: 0.2,
      ease: 'easeIn',
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.2,
      ease: 'easeIn',
    },
  },
};

export const modalMobileVariantsVertical: Variants = {
  initial: {
    y: '100%',
    transition: {
      duration: 0.2,
      ease: 'easeIn',
    },
  },
  animate: {
    y: 0,
    transition: {
      duration: 0.2,
      ease: 'easeIn',
    },
  },
  exit: {
    y: '100%',
    transition: {
      duration: 0.2,
      ease: 'easeIn',
    },
  },
};

export const modalMobileVariantsHorizontal: Variants = {
  initial: {
    x: '-100%',
    transition: {
      duration: 0.2,
      ease: 'easeIn',
    },
  },
  animate: {
    x: 0,
    transition: {
      duration: 0.2,
      ease: 'easeIn',
    },
  },
  exit: {
    x: '100%',
    transition: {
      duration: 0.2,
      ease: 'easeIn',
    },
  },
};
