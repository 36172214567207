import clsx from 'clsx';

import { Skeleton } from '@/components/ui/loaders/Skeletons/Skeletons';

import styles from './ProductCardSkeleton.module.scss';

const ProductCardSkeleton = () => {
  return (
    <article className={styles.card_wrapper}>
      <div className={styles.card_wrapper__image}>
        <Skeleton className={styles.skeleton} />
      </div>
      <div className={styles.card_wrapper__body}>
        <Skeleton className={clsx(styles.skeleton, styles.bodyPlaceholder)} />
        <Skeleton className={clsx(styles.skeleton, styles.bodyPlaceholder)} />
        <Skeleton className={clsx(styles.skeleton, styles.bodyPlaceholder)} />
      </div>
    </article>
  );
};

export default ProductCardSkeleton;
