import highlightWords from 'highlight-words';
import { memo } from 'react';

interface IHighlightWordsProps {
  text: string;
  words: string | string[];
  color: string;
}

export const HighlightWords = memo(({ text, words, color }: IHighlightWordsProps) => {
  const query = typeof words === 'string' ? words : words.join(' ');
  const chunks = highlightWords({ text, query });
  return (
    <span aria-label={text}>
      {chunks.map((chunk) => {
        if (!chunk.match) {
          return (
            <span key={chunk.key} aria-hidden>
              {chunk.text}
            </span>
          );
        }

        return (
          <span key={chunk.key} aria-hidden style={{ color }}>
            {chunk.text}
          </span>
        );
      })}
    </span>
  );
});

HighlightWords.displayName = 'HighlightWords';
