/**
 * Formats a phone number based on the provided options.
 *
 * @param {string} phoneNumber - The phone number to be formatted.
 * @param {Object} [options] - The formatting options.
 * @param {'onlySpaces' | 'withAllSigns'} [options.mode] - The formatting mode
 * @returns {string} - The formatted phone number.
 *
 * @example
 * // Returns: '+7 999 999 99 99'
 * formatPhoneNumber('+79999999999', { mode: 'onlySpaces' });
 *
 * @example
 * // Returns: '+7 (999) 999-99-99'
 * formatPhoneNumber('+79999999999', { mode: 'withAllSigns' });
 *
 * @example
 * // Returns: '+79999999999'
 * formatPhoneNumber('+79999999999');
 *
 * @example
 * // Returns: '+79999999999'
 * formatPhoneNumber('7-999-999-99-99');
 */
export const formatPhoneNumber = (
  phoneNumber: string,
  options?: {
    mode?: 'onlySpaces' | 'withAllSigns';
  }
): string => {
  const clearedPhone = phoneNumber.replace(/\D/g, '');

  if (!options || !options.mode) {
    return '+' + clearedPhone;
  }

  if (clearedPhone.length !== 11) {
    return phoneNumber;
  }

  switch (options.mode) {
    case 'onlySpaces':
      return '+' + clearedPhone.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5');
    case 'withAllSigns':
      return clearedPhone.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, '+$1 ($2) $3-$4-$5');
    default:
      return phoneNumber;
  }
};
